import PropTypes from 'prop-types';

const ValidatedByMessage = ({ validationList }) => {
  if (validationList?.length) {
    const message = validationList.length > 1
      ? `Validated by ${validationList[0].name} and ${validationList.length - 1} others`
      : `Validated by ${validationList[0].name}`;
    return (
      <div> {message} </div>
    );
  }
  return null;
};

ValidatedByMessage.defaultProps = {
  validationList: [],
};

ValidatedByMessage.propTypes = {
  validationList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    cognitoId: PropTypes.string,
    email: PropTypes.string,
  })),
};

export default ValidatedByMessage;
