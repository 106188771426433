import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import Pagination from '@mui/material/Pagination';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';

import getQuery from '../../components/utils';
import SkillFinderDropdown from '../../components/skill-finder-dropdown';
import GlossaryFilters from '../../components/glossary-filters';
import SkillFilterDropdown from '../../components/skill-filter-dropdown';
import sanity from '../../lib/sanity';
import './index.scss';
import nameToVanityURL from '../../lib/vanityURL';

const COUNT_PER_PAGE = 25;

const addFirstLetterAttrib = (list) => (
  list.map((skill) => {
    const firstLetter = skill.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...skill,
    };
  })
);

const FindSkills = () => {
  const [allSkills, setAllSkills] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const [paginatedList, setPaginatedList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [filter, setFilter] = useState('industry');
  const [industries, setIndustries] = useState([]);
  const navigate = useNavigate();

  const setPageData = (data) => {
    let options = data;
    if (data) {
      options = addFirstLetterAttrib(data);
      const firstPage = options.slice(0, COUNT_PER_PAGE);
      setPaginatedList(firstPage);
    }
    setSkillsList(options);
    return options;
  };

  useEffect(() => {
    const fetchAllSkills = async () => {
      const skillsQuery = `*[_type == "skill" && !(_id in path("drafts.**"))] | order(name asc) {
        name,
        definition,
        _id,
        _type
      }
      `;
      const skills = await sanity.fetch(skillsQuery);
      const options = setPageData(skills);
      // set full skill list to keep in state without recalculating
      setAllSkills(options);
    };
    fetchAllSkills();
  }, []);

  useEffect(() => {
    const getRoles = async () => {
      const rolesData = await getQuery('roles');
      const options = addFirstLetterAttrib(rolesData);
      setRoles(options);
    };
    getRoles();
  }, []);

  useEffect(() => {
    const getIndustries = async () => {
      const industryData = await getQuery('industries');
      const options = addFirstLetterAttrib(industryData);
      setIndustries(options);
    };
    getIndustries();
  }, []);

  // Round up to nearest whole number to get total number of pages
  const count = Math.ceil((skillsList.length) / COUNT_PER_PAGE);

  const handleChange = (event, page) => {
    const start = (page - 1) * COUNT_PER_PAGE;
    const end = start + COUNT_PER_PAGE;
    const newPage = skillsList.slice(start, end);
    setPaginatedList(newPage);
  };

  const onClick = (role) => {
    navigate(`/skill/${nameToVanityURL(role.name)}`);
  };

  const isIndustries = filter === 'industry';

  return (
    <div className="page-wrapper">
      <div className="card-wrapper">
        <div className="card-background">
          <div className="card-inner-wrapper">
            <h1>Skill Finder</h1>
          </div>
          <SkillFinderDropdown allSkills={allSkills} />
        </div>
      </div>
      <div className="card-wrapper">
        <div className="card-background">
          <div className="glossary-title-wrapper">
            <h2>Skills Glossary</h2>
            <Link to="/add-skill" className="tendo-primary-link add-skill-link">
              <AddIcon className="add-icon" />
              Add Skill
            </Link>
          </div>
          <GlossaryFilters>
            <button
              onClick={() => {
                setFilter('industry');
                setPageData(allSkills);
              }}
              className="tendo-primary-button filter-button"
              type="button"
            >
              Industry
            </button>
            <button
              onClick={() => {
                setFilter('role');
                setPageData(allSkills);
              }}
              className="tendo-primary-button filter-button"
              type="button"
            >
              Role
            </button>
            { isIndustries && (
              <SkillFilterDropdown
                list={industries}
                setPageData={setPageData}
                allSkills={allSkills}
              />
            )}
            { !isIndustries && (
              <SkillFilterDropdown
                list={roles}
                setPageData={setPageData}
                allSkills={allSkills}
              />
            )}
          </GlossaryFilters>
          <List>
            {paginatedList.map((skillInfo) => {
              const definitionText = skillInfo.definition || 'Add definition';
              return (
                <ListItem disablePadding key={skillInfo._id}>
                  <ListItemButton component="a" onClick={() => onClick(skillInfo)}>
                    <ListItemText
                      primary={skillInfo.name}
                      secondary={<div className="definition">{definitionText}</div>}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <Pagination count={count} onChange={handleChange} />
        </div>
      </div>
    </div>
  );
};

export default FindSkills;
