import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import './index.scss';

const GlossaryFilters = ({ children }) => (
  <div className="glossary-filter-wrapper">
    <MuiAccordion
      elevation={0}
    >
      <MuiAccordionSummary
        expandIcon={(
          <ArrowForwardIosSharpIcon
            sx={{ fontSize: '0.9rem' }}
            className="filter-icon"
          />
        )}
        aria-controls="filter-content"
      >
        <Typography className="filter-label">Filters</Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails>
        { children }
      </MuiAccordionDetails>
    </MuiAccordion>
  </div>
);

export default GlossaryFilters;
