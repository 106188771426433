import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import './index.scss';

const SkillFilterDropdown = ({
  list,
  setPageData,
  allSkills,
}) => {
  const type = list[0]?._type;
  const label = list[0]?.name;

  const onChange = (event, newValue) => {
    const item = newValue;
    // if clearing filter show all skills
    if (item === null) {
      return setPageData(allSkills);
    }

    // find skills attached to roles in the industry selected
    if (type === 'industry') {
      const skillsArray = [];
      item.role?.map((r) => {
        if (r.skills) {
          skillsArray.push(...r.skills);
        }
        return null;
      });
      item.skills = skillsArray;
    }

    // if no skills or roles then display nothing
    if (item.skills === null || item.roles === null) {
      setPageData([]);
    } else {
      //  set skills associated with role or industry
      setPageData(newValue.skills);
    }
    return null;
  };

  return (
    <div className="filter-dropdown-wrapper">
      <label className="dropdown-label">{type}</label>
      <Autocomplete
        onChange={(event, v) => onChange(event, v)}
        className="form-auto-complete"
        disablePortal
        id="roles-combo-box"
        getOptionLabel={(option) => option.name}
        options={list.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
        sx={{ width: 270 }}
        renderInput={(params) => (
          <TextField {...params} label={`e.g. ${label}`} />
        )}
      />
    </div>
  );
};

SkillFilterDropdown.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape(),
  ).isRequired,
  setPageData: PropTypes.func.isRequired,
  allSkills: PropTypes.arrayOf(
    PropTypes.shape(),
  ).isRequired,
};

export default SkillFilterDropdown;
