import { BrowserRouter, Routes, Route } from 'react-router-dom';

import RequireAuth from './require-auth';
import Navigation from '../components/nav';
import FindSkills from '../pages/find-skills';
import Skill from '../pages/skill';
import AddSkill from '../pages/add-skill';
import EditSkill from '../pages/edit-skill';
import UserProfile from '../pages/user-profile';
import OrganisationSkills from '../pages/organisation-skills';
import PublicProfile from '../pages/public-profile';

function App() {
  return (
    <BrowserRouter>
      <Navigation />
      <Routes>
        <Route
          path="/add-skill"
          element={(
            <RequireAuth>
              <AddSkill />
            </RequireAuth>
          )}
        />
        <Route
          path="/edit-skill/:skillId"
          element={(
            <RequireAuth>
              <EditSkill />
            </RequireAuth>
          )}
        />
        <Route
          path="/organisation"
          element={(
            <RequireAuth>
              <OrganisationSkills />
            </RequireAuth>
          )}
        />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/profile/:userId" element={<PublicProfile />} />
        <Route path="/skill/:skillId" element={<Skill />} />
        <Route path="/" element={<FindSkills />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
