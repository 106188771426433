import { API } from '@aws-amplify/api';

const getQuery = async (pathName) => {
  const apiName = 'tendo-skills-library';
  const path = `/${pathName}`;
  const myInit = {
    headers: {},
  };
  let res;
  try {
    res = await API.get(apiName, path, myInit);
  } catch (err) {
    throw Error(err);
  }
  return res;
};

export default getQuery;
