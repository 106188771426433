import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import classNames from 'classnames';
import { API } from '@aws-amplify/api';

import './index.scss';

const EditDropdown = ({
  user, setUser, setError, isOwnProfile,
}) => {
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [list, setList] = useState();

  useEffect(() => {
    const getList = async () => {
      try {
        const apiName = 'tendo-skills-library';
        const path = '/roles';
        const myInit = {
          headers: {},
        };
        const typeList = await API.get(apiName, path, myInit);
        setList(typeList);
      } catch {
        setError('Unable to find role info');
      }
    };
    getList();
  }, [setError]);

  const onClick = () => {
    setEdit(true);
  };

  const onCancelClick = () => {
    setEdit(false);
  };

  const inputClass = classNames('tendo-primary-input', 'small', {
    disabled: loading,
  });

  const hasType = user?.roleName;

  const updateUserInfo = async (e) => {
    e.preventDefault();
    setLoading(true);
    const typeId = '_id';
    const apiName = 'tendo-skills-library';
    const path = '/user';
    const myInit = {
      headers: {},
      body: {
        role: {
          roleName: value.name,
          roleId: value[typeId],
        },
      },
    };

    try {
      await API.post(apiName, path, myInit);
      const { skills } = user;
      setUser({
        ...user,
        roleName: value.name,
        roleId: value[typeId],
        skills,
      });
    } catch (err) {
      setError(err);
    }
    setEdit(false);
    setLoading(false);
    return null;
  };

  return (
    <div className="list-wrapper">
      <h3 className="dropdown-title">role</h3>
      { !edit && (
        <div className="dropdown-wrapper">
          { hasType && <p className="dropdown-field">{user?.roleName}</p> }
          { !hasType && <p className="dropdown-placeholder dropdown-field">Add role</p> }
          {isOwnProfile
          && (
            <button type="button" onClick={onClick} className="icon-button">
              <EditIcon className="edit-icon" />
            </button>
          )}
        </div>
      )}
      { edit
        && (
          <>
            <Autocomplete
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              className="form-auto-complete"
              disablePortal
              id="org-combo-box"
              getOptionLabel={(org) => org.name}
              options={list}
              sx={{ width: 270 }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
            <div className="submit-button">
              <button
                type="button"
                className={inputClass}
                onClick={onCancelClick}
              >
                Cancel
              </button>
              <button
                type="button"
                className={inputClass}
                onClick={updateUserInfo}
              >
                Update
              </button>
            </div>
          </>
        )}
    </div>
  );
};

EditDropdown.defaultProps = {
  user: PropTypes.shape({
    organisationId: null,
    organisationName: null,
  }),
  isOwnProfile: false,
};

EditDropdown.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    cognitoId: PropTypes.string,
    userEmail: PropTypes.string,
    organisationId: PropTypes.string,
    organisationName: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.shape()),
    roleName: PropTypes.string,
  }),
  isOwnProfile: PropTypes.bool,
  setUser: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

export default EditDropdown;
