import {
  useEffect,
  useState,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import { API } from '@aws-amplify/api';
import DownloadIcon from '@mui/icons-material/Download';
import { Parser } from 'json2csv';

import UserMessage from '../../components/userMessage';
import EditDropdown from '../../components/edit-dropdown';
import './index.scss';
import PublicToggle from '../../components/public-toggle';
import nameToVanityURL from '../../lib/vanityURL';

const UserProfile = () => {
  const { userId } = useParams();
  const [user, setUser] = useState({});
  const [error, setError] = useState('');

  useEffect(() => {
    const getUser = async () => {
      const apiName = process.env.REACT_APP_AWS_API_NAME;
      const path = '/user';
      const myInit = {
        headers: {},
      };
      try {
        const userRecord = await API.get(apiName, path, myInit);
        if (userRecord) setUser(userRecord);
        else setError('Unable to find user info');
      } catch (err) {
        setError('Unable to find user info');
      }
    };
    getUser();
  }, [userId]);

  const hasSkills = user?.skills;

  const fields = [{
    label: 'Skill Name',
    value: 'name',
  }, {
    label: 'Context',
    value: 'definition',
  }, {
    label: 'Category',
    value: 'category',
    default: '',
  }, {
    label: 'Metadata',
    value: 'metadata',
    default: '',
  }];
  const opts = { fields };
  const handleClick = () => {
    try {
      const parser = new Parser(opts);
      const csv = parser.parse(user.skills);
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const pom = document.createElement('a');
      pom.href = url;
      pom.setAttribute('download', 'skills.csv');
      pom.click();
    } catch (err) {
      setError('Unable to download skills');
    }
  };

  return (
    <div className="page-wrapper">
      <div className="card-wrapper">
        <h2 className="profile-title">Profile</h2>
        <div className="card-background">
          <div className="list-wrapper">
            <h3>Name</h3>
            <p>{user?.name}</p>
          </div>
          <div className="list-wrapper">
            <h3>Email</h3>
            <p>{user?.userEmail}</p>
          </div>
          <EditDropdown
            user={user}
            setUser={setUser}
            setError={setError}
            isOwnProfile
          />
          <div className="list-wrapper">
            <h3>Profile Visibility</h3>
            <PublicToggle
              setError={setError}
              setUser={setUser}
              vanityURL={user.vanityURL}
              isProfilePublic={user.isProfilePublic}
            />
          </div>
          <div className="list-wrapper">
            <h3>Export Skills</h3>
            <button type="button" onClick={handleClick} className="icon-button">
              <DownloadIcon className="download-icon" />
            </button>
          </div>
          <UserMessage errorMessage={error}>
            {error}
          </UserMessage>
        </div>
      </div>

      {hasSkills && (
      <div className="card-wrapper">
        <h2 className="profile-title">skills</h2>
        <div className="card-background skill-layout">
          { user.skills.map((skill) => (
            <Link
              key={skill._id}
              to={`/skill/${nameToVanityURL(skill.name)}`}
              className="skill-link"
            >
              {skill.name}
            </Link>
          ))}
        </div>
      </div>
      )}
    </div>
  );
};

export default UserProfile;
