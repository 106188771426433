import { useContext, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import { useLocation } from 'react-router-dom';

import { AuthContext } from '../../components/context/auth';

function RequireAuth({ children }) {
  const { state, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const loginPageUrl = process.env.REACT_APP_LOGIN_DOMAIN;

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        dispatch({
          type: 'SIGN_IN_SUCCESS',
          payload: user,
        });
      })
      .catch(() => {
        window.location.replace(loginPageUrl);
      });
  }, [dispatch, state.authenticated, location.pathname, loginPageUrl]);

  if (!state.authenticated) {
    return null;
  }

  return children;
}

export default RequireAuth;
