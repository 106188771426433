import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import { API } from '@aws-amplify/api';

import './index.scss';
import sanity from '../../lib/sanity';
import UserMessage from '../../components/userMessage';

const EditSkill = () => {
  const { skillId } = useParams();
  const [skill, setSkill] = useState([]);
  const [definition, setDefinition] = useState();
  const [error, setError] = useState();
  const [completedMessage, setCompletedMessage] = useState();
  const [loading, setLoading] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    const skillsQuery = '*[_type == "skill" && _id == $skillId]';
    const fetchData = async () => {
      const res = await sanity.fetch(skillsQuery, { skillId });
      setSkill(res[0]);
      setDefinition(res[0].definition);
    };
    fetchData();
  }, [skillId]);

  useEffect(() => {
    const getUser = async () => {
      const apiName = 'tendo-skills-library';
      const path = '/user';
      const myInit = {
        headers: {},
      };
      try {
        const userRecord = await API.get(apiName, path, myInit);
        setUser(userRecord);
      } catch (err) {
        setError(err);
      }
    };
    getUser();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const editedByName = user?.organisationName || user?.name;
    const newDoc = {
      ...skill,
      definition,
      editedBy: {
        userEmail: user.userEmail,
        cognitoId: user.cognitoId,
        name: editedByName,
      },
    };

    try {
      const apiName = 'tendo-skills-library';
      const path = `/skill/drafts.${skillId}`;
      const myInit = {
        headers: {},
        body: newDoc,
      };
      await API.patch(apiName, path, myInit);
      setCompletedMessage(true);
    } catch (err) {
      setError(`${err}`);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setDefinition(e.target.value);
  };

  const changed = definition === skill.definition;
  const disabled = loading || changed;
  const inputClass = classNames('tendo-primary-input', {
    disabled,
  });

  const successMessage = (
    <>
      Thank you for submitting an edit to this skill, it will be reviewed shortly.{' '}
      <Link to="/">Return to the Glossary</Link> {' '} to edit more skills.
    </>
  );
  const message = error || successMessage;

  return (
    <div className="page-wrapper">
      <div className="card-wrapper">
        <div className="card-background">
          { !error && !completedMessage && (
            <>
              <h1>{skill.name}</h1>
              <form onSubmit={handleSubmit}>
                <h2>Definition</h2>
                <div className="skill-definition-wrapper">
                  <TextField
                    id="edit-definition"
                    type="text"
                    className="skill-form-text-field"
                    onChange={handleChange}
                    value={definition}
                    multiline
                  />
                </div>
                <div className="submit-button">
                  <input type="submit" className={inputClass} disabled={disabled} />
                </div>
              </form>
            </>
          )}
          <UserMessage errorMessage={error} completedMessage={completedMessage}>
            {message}
          </UserMessage>
        </div>
      </div>
    </div>
  );
};

export default EditSkill;
