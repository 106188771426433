import { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { API } from '@aws-amplify/api';
import EditIcon from '@mui/icons-material/Edit';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import classnames from 'classnames';

import ValidatedByMessage from '../../components/validatedBy';
import { AuthContext } from '../../components/context/auth';
import './index.scss';

const Skill = () => {
  const { skillId } = useParams();
  const [skill, setSkill] = useState([]);
  const [validations, setValidations] = useState([]);

  const { state } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      const apiName = process.env.REACT_APP_AWS_API_NAME;
      const path = `/skill/${skillId}`;
      const myInit = {
        headers: {},
      };
      const res = await API.get(apiName, path, myInit);
      const errorRes = {
        name: 'Invalid URL Token',
        validatedBy: 'Developers',
        definition: `
          The URL Provided does not match up to our databases, please check your spelling
        `,
      };
      if (res.length === 0) {
        setSkill(errorRes);
      } else {
        setSkill(res);
        setValidations(res.validatedBy);
      }
      // }
    };
    fetchData();
  }, [skillId]);

  const hasAttribution = skill?.createdBy;
  const hasEditedBy = skill?.editedBy?.name;

  const hasAlreadyValidated = (skillValidations) => {
    if (skillValidations) {
      return skillValidations.filter(
        (user) => user?.cognitoId === state?.cognito?.attributes?.sub,
      );
    }
    return null;
  };

  const onClick = async () => {
    // can only validate if you haven't done so already
    const userValidation = validations ? hasAlreadyValidated(validations) : [];

    if (!userValidation.length && state.cognito?.attributes) {
      const { name, sub, email } = state.cognito.attributes;
      try {
        const apiName = 'tendo-skills-library';
        const path = `/skill/${skill._id}/validate`;
        const myInit = {
          headers: {},
        };
        await API.patch(apiName, path, myInit);
      } catch (e) {
        /* eslint-disable-next-line no-console */
        console.error('There has been an error adding your validation');
      }
      if (validations?.length) {
        setValidations([...validations, { cognitoId: sub, name, userEmail: email }]);
      } else {
        setValidations([{ cognitoId: sub, name, userEmail: email }]);
      }
    }
  };

  const isCreator = skill?.createdBy?.cognitoId === state.cognito?.attributes?.sub;
  // classname is disabled if already liked or it's introduced by you
  const verificationIcon = classnames('verified-icon', {
    disabled: hasAlreadyValidated(validations) || isCreator,
  });
  const introducedBy = skill?.createdBy?.name || skill?.createdBy?.organisation;

  return (
    <div className="page-wrapper">
      <div className="card-wrapper">
        <div className="card-background">
          <h1>{skill.name}</h1>
          { hasAttribution
            && (
              <p className="skill-attribution">
                Introduced By:&nbsp;
                {skill?.createdBy?.vanityURL && skill?.createdBy?.name
                  ? (
                    <Link to={`/profile/${skill?.createdBy?.vanityURL}`}>
                      {introducedBy}
                    </Link>
                  )
                  : introducedBy }
              </p>
            )}
          <h2>Definition</h2>
          <div className="skill-definition-wrapper">
            <p>{skill.definition}</p>
          </div>
          { hasEditedBy
            && (
              <p className="skill-attribution">
                Edited By:&nbsp;
                {skill?.editedBy?.vanityURL
                  ? (
                    <Link to={`/profile/${skill?.editedBy?.vanityURL}`}>
                      {hasEditedBy}
                    </Link>
                  )
                  : hasEditedBy }
              </p>
            )}
          <div className="validations">
            <VerifiedUserIcon onClick={onClick} className={verificationIcon} />
            <ValidatedByMessage validationList={validations} />
          </div>
        </div>
        <div className="action-links">
          <Link to={`/edit-skill/${skill._id}`} className="edit-skill-link">
            Edit Skill <EditIcon />
          </Link>
          <Link to="/" className="tendo-primary-link">
            View all skills
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Skill;
