import sanityClient from '@sanity/client';

export default sanityClient({
  apiVersion: '2021-08-31',
  projectId: 'aknlm4j4',
  dataset: 'production',
  // token: process.env.REACT_APP_SANITY_TOKEN,
  // useCdn: true,
  // useCdn == true gives fast, cheap responses using a globally distributed cache.
  // Set this to false if your application require the freshest possible
  // data always (potentially slightly slower and a bit more expensive).
});
