import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { API } from '@aws-amplify/api';
import { Link } from 'react-router-dom';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@mui/material';

import UserMessage from '../../components/userMessage';
import sanity from '../../lib/sanity';
import './index.scss';

const AddSkill = () => {
  const [skill, setSkill] = useState({
    id: null,
    name: '',
    definition: '',
  });
  const [error, setError] = useState();
  const [completedMessage, setCompletedMessage] = useState();
  const [loading, setLoading] = useState();
  const [user, setUser] = useState();
  const [skillsList, setSkillsList] = useState([]);

  const handleChange = (e, type) => {
    setSkill({ ...skill, [type]: e.target.value });
  };

  useEffect(() => {
    const getUser = async () => {
      const apiName = 'tendo-skills-library';
      const path = '/user';
      const myInit = {
        headers: {},
      };
      try {
        const userRecord = await API.get(apiName, path, myInit);
        setUser(userRecord);
      } catch (err) {
        setError(err);
      }
    };
    getUser();
  }, []);

  useEffect(() => {
    const skillsQuery = `*[_type == "skill"] {
      name,
      definition,
      _id,
    }
    `;
    const fetchData = async () => {
      const skills = await sanity.fetch(skillsQuery);
      setSkillsList(skills);
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // check if name already exists in current (non-draft docs) list
    const existingSkill = skillsList.filter((sk) => skill.name === sk.name);
    if (existingSkill.length) {
      setError(existingSkill[0]._id);
    } else {
      const newDoc = {
        name: skill.name,
        definition: skill.definition || '',
        createdBy: {
          name: user?.name,
          userEmail: user?.userEmail,
          organisation: user?.organisationName,
          cognitoId: user.cognitoId,
        },
      };
      try {
        const apiName = 'tendo-skills-library';
        const path = '/skill';
        const myInit = {
          headers: {},
          body: newDoc,
        };
        await API.post(apiName, path, myInit);
        setCompletedMessage(true);
      } catch (err) {
        setError(err);
      }
    }
    setLoading(false);
  };

  const skillComplete = skill.name;
  const disabledButton = !skillComplete || loading;

  const inputClass = classNames('tendo-primary-input', 'small', {
    center: skillComplete,
    disabled: disabledButton,
  });

  const submitValue = loading ? '. . .' : 'Submit';
  const getMessage = () => {
    let message;
    if (completedMessage) {
      message = (
        <>
          Thank you for submitting a new skill, it will be reviewed shortly.{' '}
          <Link to="/">Return to the Glossary</Link> {' '} to introduce a new skills.
        </>
      );
    } else {
      message = (
        <>
          A skill by this name already exists, find it{' '}
          <Link to={`/skill/${error}`} className="error-message">here</Link>
        </>
      );
    }

    return message;
  };

  return (
    <div className="page-wrapper">
      <div className="card-wrapper">
        <div className="card-background side-layout">
          { !error && !completedMessage && (
            <>
              <h1 className="form-title">Add Skill</h1>
              <form className="form-container" onSubmit={handleSubmit}>
                <FormControl>
                  <InputLabel>Skill Name</InputLabel>
                  <Input id="skill-name" aria-describedby="name of the new skill" onChange={(e) => handleChange(e, 'name')} />
                  <FormHelperText id="my-helper-text">This must be unique</FormHelperText>
                </FormControl>
                <FormControl>
                  <InputLabel>Skill Definition</InputLabel>
                  <Input id="skill-definition" aria-describedby="definition of the new skill" multiline onChange={(e) => handleChange(e, 'definition')} />
                </FormControl>
                <div className="submit-button">
                  <input type="submit" value={submitValue} className={inputClass} disabled={disabledButton} />
                </div>
              </form>
            </>
          )}
          <UserMessage completedMessage={completedMessage} errorMessage={error}>
            { getMessage() }
          </UserMessage>
        </div>
      </div>
    </div>
  );
};

export default AddSkill;
