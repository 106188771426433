import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { AuthContext } from '../context/auth';
import logo from './logo.png';
import './index.scss';

const Navigation = () => {
  const loginPageUrl = process.env.REACT_APP_LOGIN_DOMAIN;
  const signOutUrl = process.env.REACT_APP_SIGNOUT_URL;
  const { state, dispatch } = useContext(AuthContext);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        dispatch({
          type: 'SIGN_IN_SUCCESS',
          payload: user,
        });
      });
  }, [dispatch]);

  const signIn = () => {
    window.location.replace(loginPageUrl);
  };
  const signOut = () => {
    window.location.replace(signOutUrl);
  };

  const isLoggedIn = state.authenticated;
  const isOrgOwner = isLoggedIn ? state.cognito.attributes?.['custom:org_admin_id'] : false;

  return (
    <div className="navigation-wrapper">
      <Link to="/">
        <img className="navigation-link" src={logo} alt="logo" />
      </Link>
      { isOrgOwner && (
        <Link to="/organisation">
          <button
            className="tendo-primary-button"
            type="button"
          >
            Organisation Skills
          </button>
        </Link>
      )}
      { isLoggedIn && (
        <div className="nav-account-icons">
          <Link to="/profile">
            <AccountCircleIcon fontSize="large" className="nav-user-icon" />
          </Link>
          <button
            onClick={signOut}
            className="tendo-primary-button"
            type="button"
          >
            Sign out
          </button>
        </div>
      )}
      { !isLoggedIn && (
        <button
          onClick={signIn}
          className="tendo-primary-button"
          type="button"
        >
          Login
        </button>
      )}
    </div>
  );
};

export default Navigation;
