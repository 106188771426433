import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Chip, Box } from '@mui/material';
import getQuery from '../../components/utils';

import './index.scss';

function createChipsforValues(values) {
  const chips = values.map((value) => (<Chip label={value.label} title={value.title} />));
  return chips;
}

const OrganisationSkills = () => {
  const [roles, setRoles] = useState(null);
  const [progress, setProgress] = useState(null);

  useEffect(() => {
    const getRoles = async () => {
      const rolesData = await getQuery('roles');
      setRoles(rolesData);
    };
    getRoles();
  }, []);

  useEffect(() => {
    const getProgress = async () => {
      const progressData = await getQuery('organisation/skills-matrix');
      setProgress(progressData);
    };
    getProgress();
  }, []);

  function getRoleById(params) {
    return roles.find((role) => role._id === params.row.jobRoleId).name;
  }

  function createSkillsForRoleId(params) {
    const role = roles.find((r) => r._id === params.row.jobRoleId);

    let skills = [];
    if (role) {
      skills = role.skills.map((skill) => (skill));
    }
    return skills;
  }

  const columns = [
    {
      field: 'jobRole',
      headerName: 'Role',
      flex: 0.5,
      valueGetter: getRoleById,
    },
    {
      field: 'skills',
      headerName: 'Skills Required',
      flex: 1,
      valueGetter: createSkillsForRoleId,
      renderCell: (params) => (createChipsforValues(
        params.value.map(
          (param) => ({ label: param.name, title: param.definition }),
        ),
      )),
      cellClassName: 'custom-skills--cell',
    },
    {
      field: 'staffMembers',
      headerName: 'Staff',
      flex: 0.5,
      renderCell: (params) => (createChipsforValues(
        params.value.map(
          (param) => ({ label: param.name, title: `Missing Skills: ${param.missingSkills}` }),
        ),
      )),
      cellClassName: 'custom-skills--cell',
    },

  ];

  return (
    <div className="page-wrapper">
      <div className="card-wrapper">
        <h2 className="profile-title">Organisation Skills</h2>
        <div className="card-background">

          { roles && progress && (
          <Box sx={{ height: 1000 }}>
            <DataGrid
              rows={progress}
              columns={columns}
              pageSize={5}
              rowHeight={300}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
          ) }
        </div>
      </div>
    </div>
  );
};
export default OrganisationSkills;
