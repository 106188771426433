import './index.scss';
import ToggleGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { API } from '@aws-amplify/api';

const PublicToggle = ({
  vanityURL, setError, setUser, isProfilePublic,
}) => {
  const linkToPublicProfile = `${window.location.href}/${vanityURL}`;
  const [isPublic, setIsPublic] = useState(isProfilePublic);
  const [vanityEditURL, setVanityEditURL] = useState(vanityURL);
  const [loading, setLoading] = useState(false);
  const [inEdit, setInEdit] = useState(false);

  useEffect(() => {
    setVanityEditURL(vanityURL);
  }, [vanityURL]);

  useEffect(() => {
    setIsPublic(isProfilePublic);
  }, [isProfilePublic]);

  const changeVisibilityHandler = (_, newProfile) => {
    setIsPublic(newProfile);
  };

  const changeVanityURL = (e) => {
    const { value } = e.currentTarget;
    if (!value.includes('/profile/')) setVanityEditURL('');
    else {
      const removedSlashValue = value.replace('/profile/', '');
      setVanityEditURL(removedSlashValue);
    }
  };

  const updateVanityURL = async (e) => {
    e.preventDefault();
    setLoading(true);
    const apiName = 'tendo-skills-library';
    const path = '/user';
    const myInit = {
      headers: {},
      body: {
        vanityURL: vanityEditURL,
      },
    };

    try {
      await API.post(apiName, path, myInit);
      setUser((prev) => ({ ...prev, vanityURL: vanityEditURL }));
    } catch (err) {
      setError('The URL is taken.');
    }
    setInEdit(false);
    setLoading(false);
    return null;
  };

  const updateIsProfilePublic = async (e) => {
    e.preventDefault();
    setLoading(true);
    const apiName = 'tendo-skills-library';
    const path = '/user';
    const myInit = {
      headers: {},
      body: {
        isProfilePublic: isPublic,
      },
    };

    try {
      await API.post(apiName, path, myInit);
      setUser((prev) => ({ ...prev, isProfilePublic: isPublic }));
    } catch (err) {
      setError(err);
    }
    setInEdit(false);
    setLoading(false);
    return null;
  };

  const inputClass = classNames('tendo-primary-input', 'small', { disabled: loading });

  return (
    <>
      <ToggleGroup size="small" color="primary" exclusive value={isPublic} onChange={changeVisibilityHandler}>
        <ToggleButton value aria-label="public">
          Public
        </ToggleButton>
        <ToggleButton value={false} aria-label="private">
          Private
        </ToggleButton>
      </ToggleGroup>
      <br />
      {isProfilePublic !== isPublic
      && (
        <button
          type="button"
          className={inputClass}
          onClick={updateIsProfilePublic}
        >
          Update
        </button>
      )}
      {isPublic
      && (
        <>
          <h3>Custom URL</h3>
          { inEdit
            ? (
              <>
                <TextField
                  size="small"
                  id="outlined-basic"
                  value={`/profile/${vanityEditURL}`}
                  variant="outlined"
                  onChange={changeVanityURL}
                  sx={{ width: 270 }}
                />
                <div className="submit-button">
                  <button
                    type="button"
                    className={inputClass}
                    onClick={() => setInEdit(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={inputClass}
                    onClick={updateVanityURL}
                  >
                    Update
                  </button>
                </div>
              </>
            )
            : (
              <div className="custom-url">
                <a href={linkToPublicProfile}>{linkToPublicProfile}</a>
                <button type="button" onClick={() => setInEdit(true)} className="icon-button">
                  <EditIcon className="edit-icon" />
                </button>
              </div>
            )}
        </>
      )}
    </>
  );
};

PublicToggle.defaultProps = {
  vanityURL: '',
  isProfilePublic: false,
};

PublicToggle.propTypes = {
  vanityURL: PropTypes.string,
  isProfilePublic: PropTypes.bool,
  setUser: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

export default PublicToggle;
