import {
  useEffect,
  useState,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import { API } from '@aws-amplify/api';

import UserMessage from '../../components/userMessage';
import EditDropdown from '../../components/edit-dropdown';
import './index.scss';
import nameToVanityURL from '../../lib/vanityURL';

const PublicProfile = () => {
  const { userId } = useParams();
  const [user, setUser] = useState({});
  const [error, setError] = useState('');

  useEffect(() => {
    const getUser = async () => {
      const apiName = process.env.REACT_APP_AWS_API_NAME;
      const path = `/user/profile/${userId}`;
      const myInit = {
        headers: {},
      };
      try {
        const userRecord = await API.get(apiName, path, myInit);
        if (userRecord) setUser(userRecord);
        else setError('Unable to find user info');
      } catch (err) {
        setError('Unable to find user info');
      }
    };
    getUser();
  }, [userId]);

  const hasSkills = user?.skills;
  const hasRoles = user?.roleName;

  return (
    <div className="page-wrapper">
      <div className="card-wrapper">
        <h2 className="profile-title">Profile</h2>
        <div className="card-background">
          <div className="list-wrapper">
            <h3>Name</h3>
            <p>{user?.name}</p>
          </div>
          {hasRoles && (
            <EditDropdown
              user={user}
              setUser={setUser}
              setError={setError}
              isOwnProfile={false}
            />
          )}
          <UserMessage errorMessage={error}>
            {error}
          </UserMessage>
        </div>
      </div>

      {hasSkills && (
      <div className="card-wrapper">
        <h2 className="profile-title">skills</h2>
        <div className="card-background skill-layout">
          { user.skills.map((skill) => (
            <Link
              key={skill._id}
              to={`/skill/${nameToVanityURL(skill.name)}`}
              className="skill-link"
            >
              {skill.name}
            </Link>
          ))}
        </div>
      </div>
      )}
    </div>
  );
};

export default PublicProfile;
