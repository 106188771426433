import React, { createContext, useReducer } from 'react';

const initialState = {
  authenticated: false,
  cognito: null,
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SIGN_IN_SUCCESS':
      return {
        ...initialState,
        authenticated: true,
        cognito: payload,
      };
    case 'NOT_AUTHENTICATED':
      return initialState;
    default:
      throw new Error(`Unexpected action ${type}`);
  }
};

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  /* eslint-disable */
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
