import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import nameToVanityURL from '../../lib/vanityURL';

const SkillFinderDropdown = ({ allSkills }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    navigate(`/skill/${nameToVanityURL(value.name)}`);
  };

  const inputClass = value ? 'tendo-primary-input' : 'tendo-primary-input disabled';

  return (
    <form onSubmit={onSubmit}>
      <Autocomplete
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        className="form-auto-complete"
        disablePortal
        id="roles-combo-box"
        groupBy={(option) => option.firstLetter}
        getOptionLabel={(option) => option.name}
        options={allSkills.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
        sx={{ width: 270 }}
        renderInput={(params) => <TextField {...params} label="e.g. Latte Art" />}
      />
      <div className="card-inner-wrapper">
        <input type="submit" value="next" className={inputClass} />
      </div>
    </form>
  );
};

SkillFinderDropdown.propTypes = {
  allSkills: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      firstLetter: PropTypes.string,
      definition: PropTypes.string,
      _id: PropTypes.string,
    }),
  ).isRequired,
};

export default SkillFinderDropdown;
