import React from 'react';
import ReactDOM from 'react-dom/client';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import Router from './router';
import './styles/index.scss';

import { AuthContextProvider } from './components/context/auth';

// Configure AWS Amplify.
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    cookieStorage: {
      domain: process.env.REACT_APP_COOKIE_SECURE,
      secure: process.env.REACT_APP_COOKIE_SECURE,
    },
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_AWS_API_NAME,
        endpoint: process.env.REACT_APP_AWS_APIGATEWAY_URL,
        region: process.env.REACT_APP_AWS_REGION,
        custom_header: async () => {
          const headers = {};
          let jwt;

          try {
            jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
            headers.Authorization = `Bearer ${jwt}`;
          } catch (e) {
            // Ignore as it's ok to not have a token
          }
          return headers;
        },
      },
    ],
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <Router />
    </AuthContextProvider>
  </React.StrictMode>,
);
