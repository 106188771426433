import PropTypes from 'prop-types';

const UserMessage = ({ errorMessage, completedMessage, children }) => {
  if (errorMessage) {
    return <div className="error-message">{children}</div>;
  }
  if (completedMessage) {
    return <div className="completed-message">{children}</div>;
  }
  return null;
};

UserMessage.defaultProps = {
  errorMessage: '',
  completedMessage: false,
};

UserMessage.propTypes = {
  errorMessage: PropTypes.string,
  completedMessage: PropTypes.bool,
};

export default UserMessage;
